<template>
  <div>
    <el-col :span="4">
      <el-menu
        :default-active="checkIndex"
        @select="check"
        class="el-menu-vertical-demo"
        @click="check"
      >
        <el-menu-item index="1">
          <span slot="title">首页</span>
        </el-menu-item>
        <el-menu-item index="2">
          <span slot="title">企业账号中心</span>
        </el-menu-item>
        <el-menu-item index="3">
          <span slot="title">刷新置顶</span>
        </el-menu-item>
        <el-submenu index="4">
          <template slot="title">
            <span>企业招聘</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="4-1">发布招聘信息</el-menu-item>
            <el-menu-item index="4-2">管理招聘信息</el-menu-item>
            <el-menu-item index="4-3">收到的简历投递</el-menu-item>
            <el-menu-item index="4-4">已收藏简历</el-menu-item>
            <el-menu-item index="4-5">简历浏览历史</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="5">
          <!-- <template> -->
          <span slot="title">企业培训订单</span>
          <!-- </template> -->
          <!-- <el-menu-item-group> -->
          <!-- <el-menu-item index="5-1">培训信息</el-menu-item> -->
          <!-- <el-menu-item index="5-2">培训</el-menu-item> -->
          <!-- <el-menu-item index="5-3">培训学校</el-menu-item> -->
          <!-- </el-menu-item-group> -->
        </el-menu-item>
        <!-- <el-submenu index="6">
                <template slot="title">
                  <span>企业办证</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="6-1">办证信息</el-menu-item>
                  <el-menu-item index="6-2">办证订单</el-menu-item>
                </el-menu-item-group>
              </el-submenu> -->
        <el-menu-item index="11">
          <span slot="title">派遣船员</span>
        </el-menu-item>
        <el-menu-item index="12">
          <span slot="title">班期培训</span>
        </el-menu-item>
        <el-menu-item index="7">
          <span slot="title">企业公告</span>
        </el-menu-item>
        <el-menu-item index="8">
          <span slot="title">中介担保</span>
        </el-menu-item>
        <!-- <el-menu-item index="9">
                <span slot="title">企业资料</span>
              </el-menu-item> -->
        <el-menu-item index="10">
          <span slot="title">我的订阅</span>
        </el-menu-item>
      </el-menu>
    </el-col>
    <el-dialog title="提示" :visible.sync="showSub" width="30%">
      <span v-if="subform.subscribe" style="margin-right: 20px">是否关闭订阅</span>
      <span v-else style="margin-right: 20px">是否开启订阅</span>
      <el-radio v-model="subscription" :label="true">是</el-radio>
      <el-radio v-model="subscription" :label="false">否</el-radio>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSub = false">取 消</el-button>
        <el-button type="primary" @click="editSub">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/BaseMixin.vue";
export default {
  mixins: [BaseMixin],
  props: {
    userFormW: Object,
  },
  data() {
    return {
      checkIndex: this.uni.getStorageSync("qyCheckIndex") || "1",
      showSub: false,
      subscription: true,
      subform: {
        subscribe: false,
      },
    };
  },
  components: {},
  watch: {
    userFormW: {
      handler(newV) {
        console.log("newV", newV);
        if (newV) {
          this.subform = newV;
          this.subscription = this.subform.subscribe;
          console.log("this.subscription", this.subscription);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initData() {},
    editSub() {
      if (this.subscription) {
        this.$u.api.updateSubscribe(this.subscription + "").then((res) => {
          this.uni.showToast("订阅开启成功");
          this.subform.subscribe = true;
          this.$u.vuex("vuex_user", this.subform);
          this.showSub = false;
        });
      } else {
        this.$u.api.updateSubscribe(this.subscription + "").then((res) => {
          this.uni.showToast("订阅关闭成功");
          this.subform.subscribe = false;
          this.$u.vuex("vuex_user", this.subform);
          this.showSub = false;
        });
      }
    },
    check(index) {
      if (index == "1") {
        this.jump("enterprise_index");
      } else if (index == "2") {
        this.jump("ec_account_manage");
      } else if (index == "3") {
        this.jump("ec_buy_package");
      } else if (index == "4-1") {
        this.jump("post_recruitment_info");
      } else if (index == "4-2") {
        this.jump("manage_recruitment_info");
      } else if (index == "4-3") {
        this.jump("resume_delivery_received");
      } else if (index == "4-4") {
        this.jump("collected_resume");
      }else if (index == "4-5") {
        this.jump("companyResume");
      }  else if (index == "5-1") {
        this.jump("ec_training_info");
      } else if (index == "5") {
        this.jump("ec_training_order");
      } else if (index == "5-3") {
        this.jump("training_school");
      } else if (index == "7") {
        this.jump("corporate_announcements");
      } else if (index == "8") {
        this.jump("ec_mediation_guarantee");
      } else if (index == "10") {
        this.showSub = true;
      }else if (index == "11") {
        this.jump("dispatch_crew");
      }else if (index == "12") {
        this.jump("release_schedule");
      }
      if (index != "10") {
        this.checkIndex = index;
        this.$u.vuex("qyCheckIndex", this.checkIndex);
      }
    },
  },
};
</script>
<style>
page {
  background: #fafafa;
}
</style>
<style lang="less" scoped>
.personal {
  margin: 10px 18.75vw 6.25vw;

  .menuContent {
    background: #fff;
    padding: 1.5625vw 2.083vw;
  }
}
</style>
