<script>
const pageParams = {}
function requestUrlParam() {
  const url = location.href
  const arrStr = url.substring(url.indexOf("?") + 1).split("&")
  const obj = {};
  for (var i = 0; i < arrStr.length; i++) {
    const item = arrStr[i].split('=')
    let key = item[0];
    let val = decodeURI(item[1]);
    obj[key] = val;
  }
  return obj
}
// import * as ui from '@/utils/ui'
const config = require('@/config/config')
const hrefHost = process.env.NODE_ENV === "development" ? config.devHref : config.prodHref
export default {
  data() {
    return {
      isDoRefresh: true,
      pageParams,
      // $ui: ui
    }
  },
  onLoad(query = {}) {
    this.initParams(query)
  },
  onShow() {
    this.onShow()
  },
  mounted() {
    this.initData()
  },
  created() {
    console.log('当前pageParams', this.pageParams);
    this.pageParams = requestUrlParam()
    // console.log("3-index - created")
    // this.initData()
  },
  onReady() {
    // console.log("4-index - onReady")
  },
  methods: {
    winBack() {
      window.history.go(-1);
    },
    getTop() {
      window.scrollTo({})
      // this.uni.pageScrollTo({
      //   scrollTop: 0, duration: 300
      // })
    },
    getJsSDK() {
      const that = this
      that.$u.api.wxConfig().then(res => {
        console.log(res)
        that.$jwx.initWxConfig(res.data)
      })
    },
    getLoad() {
      // this.initParams(query)
    },
    initParams(query) {
      this.pageParams = query || {}
    },
    initData() {
      console.log('initData')
    },
    // 退出登录
    fedLogOut() {
      this.btnStatus = false;
      this.loginStatus = false;
      this.vuex_token = false; // 企业token
      this.member_token = false; // 用户token
      this.vuex_user = '';
      localStorage.removeItem('loginStatus')
      localStorage.removeItem('vuex_token')
      localStorage.removeItem('member_token')
      localStorage.removeItem('vuex_user')
      // window.location.reload()
      this.jump("login")
    },
    onShow() {
      // console.log(this.isDoRefresh)
      // const pages = getCurrentPages()
      // const currPage = pages[pages.length - 1]
      // if (currPage.data) {
      //   if (currPage.data.isDoRefresh === true) {
      //     this.isDoRefresh = false
      //     this.initData()
      //   }
      // }
      // if (currPage.$vm) {
      //   if (currPage.$vm.isDoRefresh === true) {
      //     currPage.$vm.isDoRefresh = false
      //     this.initData()
      //   }
      // }
      // if (this.currPageRoute !== currPage.route) {
      //   this.SET_CURRPAGE_ROUTE(currPage.route)
      // }
    },
    backRefresh(delta = 1) {
      // const pages = getCurrentPages()
      // console.log(pages);
      // if (pages && pages.length > delta) {
      //   const prevPage = pages[pages.length - 2]
      //   if (prevPage.$vm) {
      //     prevPage.$vm.isDoRefresh = true
      //   }
      //   if (prevPage.data) {
      //     prevPage.data.isDoRefresh = true
      //   }
      //   if (delta > 1) {
      //     const prevPrevPage = pages[pages.length - 3]
      //     if (prevPrevPage.$vm) {
      //       prevPrevPage.$vm.isDoRefresh = true
      //     }
      //     if (prevPrevPage.data) {
      //       prevPrevPage.data.isDoRefresh = true
      //     }
      //   }
      // }
    },
    clickButtons() {
      this.$dy_tool.openURL('http://www.baidu.com')
    },
    call(phone) {

    },
    jump(url) {
      // this.$notify({
      //   title: '成功',
      //   message: '跳转路径' + url,
      //   type: 'success'
      // });
      console.log("")
      console.log('跳转路径', hrefHost + url + '.html',process.env.NODE_ENV,config);
      if (typeof url === 'string' && url.indexOf('?') !== -1) {
        // url存在？号 
        const urlName = url.split('?')[0];
        const urlParam = url.split('?')[1];
        window.location.href = hrefHost + urlName + '.html?' + urlParam
      } else {
        window.location.href = hrefHost + url + '.html'
      }
    },
    jumps() {
      //用来判断是否跳转登录页
      // console.log('1111',this.vuex_token)
      this.$dy_tool.toLogin(this.vuex_token)
    },
    viewImage(urls, url) {// 图片预览
      this.$uniApi.previewImage(urls, url)
    },
    copy(value) {
      // 创建输入框元素
      let oInput = document.createElement('input');
      // 将想要复制的值
      oInput.value = value;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      this.uni.showToast('复制成功');
      // 复制后移除输入框
      oInput.remove();
    },
    compress(img) {// 压缩图片 图文分享要求分享图片大小不能超过20Kb
      let tmpImg = ''
      if (img.startsWith('http')) {
        tmpImg = this.$uniApi.downloadFile(img)
      } else {
        tmpImg = img
      }
      if (!tmpImg) {
        return
      }
      console.log('开始压缩')
      // return new Promise((res) => {
      //   const localPath = tmpImg
      //   console.log('after' + localPath)
      //   // 压缩size
      //   plus.io.resolveLocalFileSystemURL(localPath, (entry) => {
      //     entry.file((file) => {// 可通过entry对象操作图片
      //       console.log('getFile:' + JSON.stringify(file))
      //       if (file.size > 20480) {// 压缩后size 大于20Kb
      //         plus.zip.compressImage({
      //           src: tmpImg,
      //           dst: tmpImg.replace('.jpg', '2222.jpg').replace('.JPG', '2222.JPG'),
      //           width: '10%',
      //           height: '10%',
      //           quality: 1,
      //           overwrite: true
      //         }, (event) => {
      //           console.log('success zip****' + event.size)
      //           const newImg = tmpImg.replace('.jpg', '2222.jpg').replace('.JPG', '2222.JPG')
      //           res(newImg)
      //         }, (error) => {
      //           console.error('图片压缩错误：', error)
      //         })
      //       }
      //     })
      //   }, (error) => {
      //     console.error('图片压缩错误：', error)
      //   })
      // })
    },
    openAddress(res) {
    }
  }
}
</script>
