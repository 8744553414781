import http from './index.js'

export function loginApi(vm) {
  // console.log('执行loginApi', http.get('/member/getInfo'));
  // http.get('/member/getInfo');
  // console.log('执行loginApi', );
  // 请求方法定义区域
  const fedLogOut = () => { // 前端登出
    vm.$u.vuex('loginStatus', false)
    vm.$u.vuex('vuex_token', '')
    vm.$u.vuex('member_token', '')
    vm.$u.vuex('vuex_user', {})
    console.log('退出登录', vm.member_token);
  }
  const testLogin = (params = {}) => {
    return http.post('/auth/testLogin', params)
  }
  const memberPhoneLogin = (params = {}) => {
    return http.post('/auth/memberPhoneLogin', params)
  }
  const getInfo = (params = {}) => {
    return http.get('/member/getInfo', params)
  }
  const memberRegister = (params = {}) => {
    return http.post('/auth/memberRegister', params)
  }
  const business = (params = {}) => { //公司业务
    return http.get('/config/business', params)
  }
  const companyRegister = (params = {}) => { //新公司注册
    return http.post('/auth/companyRegister', params)
  }
  const companyPhoneLogin = (params = {}) => { //公司手机号密码登录
    return http.post('/auth/companyPhoneLogin', params)
  }
  // 将各个定义的接口名称，统一放进对象挂载到vm.$u.api(因为vm就是this，也即this.$u.api)下
  return {
    fedLogOut,
    testLogin,
    getInfo,
    memberPhoneLogin,
    memberRegister,
    business,
    companyRegister,
    companyPhoneLogin
  }
}
export function comm(vm) {
  const getPackage = (params = {}) => {
    // console.log('cid:', vm.cid)
    // 获取版本
    return http.get('/config/getPackage', params)
  }
  const dict = (params = {}) => {
    return http.get('/config/dict', params)
  }
  const verify = (params = {}) => {
    return http.post('/auth/verify', params)
  }
  const configSym = (params = {}) => {
    return http.get('/config/config', params)
  }
  return {
    verify,
    getPackage,
    dict,
    configSym
  }
}

export function mainApi(vm) {
  const indexInfo = (params = {}) => {
    return http.get('/index/info', params)
  }
  const trainingList = (params = {}) => {
    return http.get('/training/searchList', params)
  }
  const category = (params = {}) => {
    return http.get('/category/get', params)
  }
  const startTimeList = (params = {}) => {
    return http.get('/training/startTimeList', params)
  }
  const areaList = (params = {}) => {
    return http.get('/training/areaList', params)
  }
  const schoolList = (params = {}) => {
    return http.get('/training/schoolList', params)
  }
  const schoolInfo = (params = {}) => {
    return http.get('/training/schoolInfo', params)
  }
  const articles = (params = {}) => {
    return http.get('/config/articles', params)
  }
  const articleDetail = (params = {}) => {
    return http.get('/config/articleDetail', params)
  }
  const articleContent = (params = {}) => { //公告详情
    return http.get('/article/content', params)
  }
  const certManagement = (params = {}) => {
    return http.get('/member/certManagement', params)
  }
  const updateInfo = (params = {}) => {
    return http.post('/member/updateInfo', params)
  }
  const queryCert = (params = {}) => {
    return http.get('/member/queryCert', params)
  }
  const orderList = (params = {}) => {
    return http.get('/trainingOrder/list', params)
  }
  const updatePassword = (params = {}) => {
    return http.post('/member/updatePassword', params)
  }
  const feedbackList = (params = {}) => {
    return http.get('/feedback/list', params)
  }
  const feedbackInfo = (params = {}) => {
    return http.get('/feedback/info', params)
  }
  const feedbackSave = (params = {}) => {
    return http.post('/feedback/save', params)
  }
  const applyOnlineDate = (params = {}) => {
    return http.get('/training/applyOnlineDate', params)
  }
  const applyOnlineTra = (params = {}) => {
    return http.get('/training/applyOnlineTra', params)
  }
  const trainingInfo = (params = {}) => {
    return http.get('/training/trainingInfo', params)
  }
  const orderCreate = (params = {}) => {
    return http.post('/trainingOrder/create', params)
  }
  const recentOrder = (params = {}) => {
    return http.get('/trainingOrder/recentOrder', params)
  }
  const orderPay = (params = {}) => {
    return http.post('/pay', params)
  }
  const trainingOrderInfo = (params = {}) => { //培训订单详情
    return http.get('/trainingOrder/info', params)
  }
  const recruitTop = (params = {}) => {
    return http.get('/recruit/top', params)
  }
  const resumeList = (params = {}) => { // 简历列表
    return http.get('/resume/list', params)
  }
  const resumeInfo = (params = {}) => { // 简历详情
    return http.get('/resume/resumeInfo', params)
  }
  const recruitIsCollect = (params = {}) => { // 是否收藏招聘
    return http.get('/recruit/isCollect', params)
  }
  const resumeIsCollect = (params = {}) => { // 是否收藏简历
    return http.get('/resume/isCollect', params)
  }
  const getAgencyInfo = (params = {}) => { // 获取中介担保信息
    return http.get('/agencyOrder/getAgencyInfo', params)
  }
  const recruitList = (params = {}) => {
    return http.get('/recruit/list', params)
  }
  const memberCollect = (params = {}) => { //收藏/取消收藏公司招聘
    return http.post('/member/collect', params)
  }
  const resumeCollect = (params = {}) => { //收藏/取消收藏简历
    return http.post('/collect', params)
  }
  const memberSendResume = (params = {}) => { //投递简历
    return http.post('/member/sendResume', params)
  }
  const recruitInfo = (params = {}) => { //招聘详情
    return http.get('/recruit/info', params)
  }
  const getRecruit = (params = {}) => { //获取可使用的招聘套餐
    return http.get('/recruit/getRecruit', params)
  }
  const getCompanyList = (params = {}) => {
    return http.get('/company/getCompanyList', params)
  }
  const companyDetail = (params = {}) => { //企业详情
    return http.get('/company/detail', params)
  }
  const articleList = (params = {}) => {
    return http.get('/article/list', params)
  }
  const datumList = (params = {}) => { //资料下载
    return http.get('/data/list', params)
  }
  const pagerCategory = (params = {}) => { //办证类别
    return http.get('/pager/category', params)
  }
  const pagerList = (params = {}) => { //办证列表
    return http.get('/pager/list', params)
  }
  const articleCategor = (params = {}) => { //文本类别
    return http.get('/article/category', params)
  }
  const pagerDetail = (params = {}) => { //证书详情
    return http.get('/pager/detail', params)
  }
  const papersOrderCreate = (params = {}) => { //创建办证订单
    return http.post('/papersOrder/create', params)
  }

  const agencyOrderCreate = (params = {}) => { //中介担保办理
    return http.post('/agencyOrder/create', params)
  }
  const trainingSearchPCList = (params = {}) => { //培训课程列表
    return http.get('/training/searchPCList', params)
  }
  const companyMissPassword = (params = {}) => { //公司忘记密码
    return http.post('/auth/companyMissPassword', params)
  }
  const memberMissPassword = (params = {}) => { //用户忘记密码
    return http.post('/auth/memberMissPassword', params)
  }
  const addAgencyApply = (params = {}) => { //提交申请中介担保
    return http.post('/add', params)
  }
  const checkAgencyApply = (params = {}) => { //是否已申请中介担保
    return http.get('/check', params)
  }
  const agencyOrderUnfinished = (params = {}) => { //获取未完成的中介担保订单
    return http.get('/agencyOrder/getAgencyOrder', params)
  }
  const messageList = (params = {}) => { //资讯列表
    return http.get('/message/list', params)
  }
  const messageCategory = (params = {}) => { //资讯类别
    return http.get('/message/category', params)
  }
  const messageInfo = (params = {}) => { //资讯详情
    return http.get('/message/info', params)
  }
  const getOpenAgencyCompanys = (params = {}) => { //中介担保公司
    return http.get('/company/getOpenAgencyCompanys', params)
  }

  return {
    indexInfo,
    trainingList,
    category,
    startTimeList,
    areaList,
    schoolList,
    schoolInfo,
    articles,
    articleDetail,
    certManagement,
    updateInfo,
    queryCert,
    orderList,
    updatePassword,
    feedbackList,
    feedbackInfo,
    feedbackSave,
    applyOnlineDate,
    applyOnlineTra,
    trainingInfo,
    orderCreate,
    recentOrder,
    orderPay,
    trainingOrderInfo,
    recruitTop,
    resumeList,
    recruitList,
    getRecruit,
    getCompanyList,
    articleList,
    datumList,
    pagerCategory,
    pagerList,
    articleCategor,
    recruitInfo,
    resumeInfo,
    memberCollect,
    memberSendResume,
    getAgencyInfo,
    pagerDetail,
    agencyOrderCreate,
    recruitIsCollect,
    resumeIsCollect,
    resumeCollect,
    articleContent,
    companyDetail,
    papersOrderCreate,
    trainingSearchPCList,
    companyMissPassword,
    memberMissPassword,
    addAgencyApply,
    checkAgencyApply,
    agencyOrderUnfinished,
    messageList,
    messageCategory,
    messageInfo,
    getOpenAgencyCompanys,
  }
}
export function userApi(vm) {
  const getResume = (params = {}) => { //获取用户简历
    return http.get('/member/getResume', params)
  }
  const sendList = (params = {}) => {
    return http.get('/member/sendList', params)
  }
  const memberCollectList = (params = {}) => {
    return http.get('/member/collectList', params)
  }
  const paperOderList = (params = {}) => {
    return http.get('/papersOrder/list', params)
  }
  const paperOderInfo = (params = {}) => { //办证订单详情
    return http.get('/papersOrder/info', params)
  }
  const agencyMemberOrder = (params = {}) => { //用户中介担保订单
    return http.get('/agencyOrder/memberOrder', params)
  }
  const agencyOrderInfo = (params = {}) => { //中介担保订单详情
    return http.get('/agencyOrder/info', params)
  }
  const resumeGetResume = (params = {}) => { //获取可使用简历套餐
    return http.get('/resume/getResume', params)
  }
  const getResumePacket = (params = {}) => { //简历套餐列表
    return http.get('/resume/getResumePacket', params)
  }
  const editResume = (params = {}) => { //新增简历
    return http.post('/member/editResume', params)
  }
  const recommendJobList = (params = {}) => { //推荐招聘列表
    return http.get('/resume/recommendJob', params)
  }
  const resumeCertificate = (params = {}) => { // 简历证书
    return http.get('/resume/certificateList', params)
  }
  const resumeRefresh = (params = {}) => { // 刷新简历
    return http.get('/resume/refresh', params)
  }
  const resumeDel = (params = {}) => { //删除简历
    return http.get('/resume/del', params)
  }
  const openChanges = (params = {}) => { //简历公开/隐藏
    return http.get('/resume/openChanges', params)
  }
  const resumeTop = (params = {}) => { //置顶简历
    return http.get('/resume/top', params)
  }
  const resumeOrderCreate = (params = {}) => { //简历套餐购买
    return http.post('/resumeOrder/create', params)
  }
  const recruitOrderCreate = (params = {}) => { //招聘套餐购买
    return http.post('/recruitOrder/create', params)
  }
  const resumeOneKeyRefresh = (params = {}) => { //一键刷新简历
    return http.get('/resume/oneKeyRefresh', params)
  }
  const agencyOrderCancel = (params = {}) => { //取消中介担保订单
    return http.post('/agencyOrder/cancel', params)
  }
  const agencyOrderFeedback = (params = {}) => { //用户反馈-是否上船
    return http.post('/agencyOrder/feedback', params)
  }
  const memberUpdatePassword = (params = {}) => { //用户修改密码
    return http.post('/member/memberUpdatePassword', params)
  }
  const memberInterviewNotice = (params = {}) => { //面试通知
    return http.get('/member/interviewNotice', params)
  }
  const companyResume = (params = {}) => { // 公司简历记录列表
    return http.get('/companyResume', params)
  }
  return {
    companyResume,
    memberInterviewNotice,
    recommendJobList,
    getResume,
    sendList,
    memberCollectList,
    paperOderList,
    agencyMemberOrder,
    resumeGetResume,
    getResumePacket,
    editResume,
    resumeCertificate,
    resumeRefresh,
    resumeDel,
    openChanges,
    agencyOrderInfo,
    paperOderInfo,
    resumeTop,
    resumeOrderCreate,
    recruitOrderCreate,
    resumeOneKeyRefresh,
    agencyOrderCancel,
    agencyOrderFeedback,
    memberUpdatePassword
  }
}
export function companyApi(vm) {
  const companyLoginInfo = (params = {}) => { //企业信息
    return http.get('/company/loginInfo', params)
  }
  const infoUpdateApply = (params = {}) => { //企业信息修改申请
    return http.post('/infoUpdateApply', params)
  }
  const infoUpdateRecord = (params = {}) => { //企业信息修改申请记录
    return http.get('/infoUpdateRecord', params)
  }
  const getCompanyContracts = (params = {}) => { //企业联系人
    return http.get('/getCompanyContracts', params)
  }
  const updateContracts = (params = {}) => { //企业修改联系方式
    return http.post('/updateContracts', params)
  }
  const companyUpdatePassword = (params = {}) => { //企业修改密码
    return http.post('/updatePassword', params)
  }
  const getBusiness = (params = {}) => { //企业业务
    return http.get('/getBusiness', params)
  }
  const getRecruitPacket = (params = {}) => { //招聘套餐
    return http.get('/recruit/getRecruitPacket', params)
  }
  const editRecruit = (params = {}) => { //新增或修改招聘
    return http.post('/editRecruit', params)
  }
  const recruitOpenChanges = (params = {}) => { //隐藏/公开招聘
    return http.get('/recruit/openChanges', params)
  }
  const recruitRefresh = (params = {}) => { //刷新招聘
    return http.get('/recruit/refresh', params)
  }
  const recruitTop = (params = {}) => { //置顶招聘
    return http.get('/recruit/top', params)
  }
  const companyRecruit = (params = {}) => { //招聘记录列表
    return http.get('/companyRecruit', params)
  }
  const companyRecruitPC = (params = {}) => { //招聘记录列表pc端
    return http.get('/companyRecruitPC', params)
  }
  const getSend = (params = {}) => { //用户投递到公司的简历
    return http.get('/getSend', params)
  }
  const collectList = (params = {}) => { //收藏的简历列表
    return http.get('/collectList', params)
  }
  const editTraining = (params = {}) => { //新增或修改培训
    return http.post('/editTraining', params)
  }
  const companyTraining = (params = {}) => { //企业培训列表
    return http.get('/companyTraining', params)
  }
  const companyTrainingPC = (params = {}) => { //企业培训列表pc端
    return http.get('/companyTrainingPC', params)
  }
  const trainingGetTags = (params = {}) => { //培训标签
    return http.get('/training/getTags', params)
  }
  const trainingUpChanges = (params = {}) => { //培训上下架
    return http.get('/training/upChanges', params)
  }
  const companyOrders = (params = {}) => { //培训订单
    return http.get('/companyOrders', params)
  }
  const school = (params = {}) => { //企业的学校信息
    return http.get('/school', params)
  }
  const editSchool = (params = {}) => { //新增或修改学校信息
    return http.post('/editSchool', params)
  }
  const companyArticle = (params = {}) => { //公司公告列表
    return http.get('/companyArticle', params)
  }
  const companyArticleCategory = (params = {}) => { //公司公告类别获取
    return http.get('/companyArticleCategory', params)
  }
  const showChanges = (params = {}) => { //隐藏/显示公司公告
    return http.get('/showChanges', params)
  }
  const companyArticleAdd = (params = {}) => { //新增公司公告
    return http.post('/companyArticleAdd', params)
  }
  const agencyCompanyOrder = (params = {}) => { //公司中介担保订单
    return http.get('/agencyOrder/companyOrder', params)
  }
  const agencyOrderRefundApply = (params = {}) => { //中介担保订单退款
    return http.post('/agencyOrder/refundApply', params)
  }
  const recruitOneKeyRefresh = (params = {}) => { //一键刷新招聘
    return http.get('/recruit/oneKeyRefresh', params)
  }
  const delRecruit = (params = {}) => { //删除招聘
    return http.post('/delRecruit', params)
  }
  const agencyOrderIfBoat = (params = {}) => { //企业标注-是否上船
    return http.post('/agencyOrder/ifBoat', params)
  }
  const updateSubscribe = (params = {}) => { //企业-订阅/取消订阅
    return http.post('/updateSubscribe', params)
  }
  const resumeBrowsingHistory = (params = {}) => { //企业简历浏览记录
    return http.get('/api/resumeBrowsingHistory/list', params)
  }
  const delCompanyArticle = (params = {}) => { // 删除企业公告（包含 培训班期） 可以多选删除
    return http.post('/delCompanyArticle', params)
  }
  const companyRefreshTsa = (params = {}) => { // 刷新 企业公告类型-培训班期
    return http.get('/refresh/tsa', params)
  }
  const companyOneKeyRefresh = (params = {}) => { // 一键刷新 企业公告类型-培训班期
    return http.get('/oneKeyRefresh/tsa', params)
  }
  const companyTopTsa = (params = {}) => { // 置顶 企业公告类型-培训班期
    return http.get('/top/tsa', params)
  }
  const companyEditResume = (params = {}) => { // 企业新增修改派遣船员
    return http.get('/editResume', params)
  }
  return {companyEditResume,
    companyRefreshTsa,
    companyOneKeyRefresh,
    companyTopTsa,
    delCompanyArticle,
    resumeBrowsingHistory,
    companyLoginInfo,
    infoUpdateApply,
    infoUpdateRecord,
    getBusiness,
    updateContracts,
    getCompanyContracts,
    companyUpdatePassword,
    getRecruitPacket,
    editRecruit,
    recruitOpenChanges,
    companyOrders,
    recruitRefresh,
    recruitTop,
    companyTraining,
    companyTrainingPC,
    trainingGetTags,
    trainingUpChanges,
    companyRecruit,
    companyRecruitPC,
    getSend,
    collectList,
    editTraining,
    school,
    editSchool,
    companyArticle,
    showChanges,
    companyArticleCategory,
    companyArticleAdd,
    agencyCompanyOrder,
    agencyOrderRefundApply,
    recruitOneKeyRefresh,
    delRecruit,
    agencyOrderIfBoat,
    updateSubscribe
  }
}
// 此处第二个参数vm，就是我们在页面使用的this，你可以通过vm获取vuex等操作，更多内容详见uView对拦截器的介绍部分：https://uviewui.com/js/http.html
const install = (Vue, vm) => {
  vm.$u.api = {
    ...loginApi(vm),
    ...mainApi(vm),
    ...comm(vm),
    ...userApi(vm),
    ...companyApi(vm)
  }
}
export default {
  install
}