<template>
  <div>
    <div class="enterprise">
      <div style="background-color: #fff">
        <el-row>
          <gsLeft></gsLeft>
          <el-col :span="20">
            <div class="menuContent">
              <div>
                <div class="trainInfo">
                  <div class="trainInfo-title">企业公告</div>
                  <el-button type="default" @click="jump('add_announcements')"
                    >发布公告</el-button
                  >
                </div>
                <el-form ref="form" :model="formInline" label-width="100px">
                  <el-form-item label="发布时间：">
                    <!-- <el-button type="text" @click="checkStatus">不限</el-button> -->
                    <el-col :span="11">
                      <el-date-picker
                        v-model="formInline.createTime"
                        type="datetimerange"
                        align="right"
                        unlink-panels
                        clearable
                        value-format="yyyy-MM-dd hh:mm:ss"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @change="dateChange"
                      ></el-date-picker>
                    </el-col>
                  </el-form-item>
                  <el-form-item label="公告状态：">
                    <el-button type="text" @click="checkStatus('')">不限</el-button>
                    <!-- <el-button type="text" @click="checkStatus()">已发布</el-button> -->
                    <el-button type="text" @click="checkStatus('isShowf')"
                      >已隐藏</el-button
                    >
                    <el-button type="text" @click="checkStatus('isShowt')"
                      >已显示</el-button
                    >
                  </el-form-item>
                  <el-form-item label="公告类型：">
                    <el-select
                      v-model="formInline.categoryName"
                      @change="typeChange"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in types"
                        :key="item.name"
                        :label="item.label"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="公告搜索：" class="enterprise-search">
                    <el-input
                      placeholder="输入公告标题"
                      v-model="formInline.title"
                      @input="clearInput"
                      class="input-with-select"
                    >
                    </el-input>
                    <el-button
                      @click="initData(formInline)"
                      ref="searchBtn"
                      :type="formInline.title ? 'primary' : 'info'"
                      >搜索</el-button
                    >
                  </el-form-item>
                </el-form>

                <noticeTable
                  :tableData="tableData"
                  @handleSelectionChange="handleSelectionChange"
                />
                <div class="tableBottom">
                  <div>
                    <!-- <el-button type="text" @click="handleEdit(scope.$index, scope.row)"
                      >批量发布</el-button
                    >
                    <el-button type="text" @click="handleEdit(scope.$index, scope.row)"
                      >批量隐藏</el-button
                    > -->
                    <el-button
                      type="text"
                      @click="handleBatchDel(scope.$index, scope.row)"
                      >批量删除</el-button
                    >
                  </div>
                  <div style="text-align: right">
                    <el-pagination
                      style="margin-top: 20px; text-align: right"
                      :current-page="currentPage"
                      :page-sizes="[10, 20, 50, 100]"
                      :page-size="10"
                      layout="prev, pager, next, jumper"
                      :total="total"
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                    />
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/BaseMixin.vue";
import gsLeft from "@/component/gs_left.vue";
import noticeTable from "@/component/noticeTable.vue";
export default {
  mixins: [BaseMixin],
  components: { gsLeft, noticeTable },
  data() {
    return {
      checkIndex: "7",
      form: {
        date: "",
        employJob: "选项1",
        search: "",
      },
      options: [
        {
          value: "选项1",
          label: "全部类型",
        },
        {
          value: "选项2",
          label: "机工",
        },
      ],
      tableData: [],
      select: "1",
      currentPage: 1,
      total: 1,
      formInline: {
        categoryName: "", // 搜索字段
        orderNum: "",
        status: 0,
        page: 0, // 当前页
        size: 10,
      },
      types: [],
      // 删除的数组
      delIds: [],
    };
  },
  methods: {
    initData(formInline) {
      this.$u.api.companyArticle(formInline).then((res) => {
        this.total = res.data.totalElements;
        this.tableData = res.data.content;
      });
      this.$u.api.companyArticleCategory().then((res) => {
        this.types = res.data;
        // this.selectItem(res.data, this.types);
      });
    },
    onSubmit() {
      console.log("submit!");
    },
    handleHide(index, row) {
      if (row.isShow) {
        this.$u.api.showChanges({ id: row.id, isShow: !row.isShow }).then((res) => {
          this.uni.showToast("公告隐藏成功");
          this.initData();
        });
      } else {
        this.$u.api.showChanges({ id: row.id, isShow: !row.isShow }).then((res) => {
          this.uni.showToast("公告显示成功");
          this.initData();
        });
      }
    },
    handleSelectionChange(ids) {
      this.delIds = ids;
    },
    // 批量删除
    handleBatchDel() {
      if (this.delIds.length > 0) {
        this.$dialog.confirm({ title: "温馨提示", message: "是否确认删除" }).then(() => {
          this.$u.api.delCompanyArticle(this.delIds).then((res) => {
            this.uni.showToast("公告删除成功");
          });
        });
      }
    },
    checkStatus(e) {
      if (e == "") {
        this.formInline.isShow = "";
        this.initData(this.formInline);
      } else if (e == "isShowf") {
        this.formInline.isShow = false;
        this.initData(this.formInline);
      } else if (e == "isShowt") {
        this.formInline.isShow = true;
        this.initData(this.formInline);
      }
    },
    clearInput(e) {
      if (e == "") {
        this.formInline.title = "";
        this.initData(this.formInline);
      }
      //
    },
    dateChange(e) {
      this.initData(this.formInline);
    },
    typeChange(e) {
      //
      this.initData(this.formInline);
      // console.log(this.formInline);
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.initData(this.formInline);
    },
    handleCurrentChange(val) {
      this.formInline.page = val - 1;
      this.initData(this.formInline);
    },
  },
};
</script>
<style>
page {
  background: none;
}

.enterprise-search >>> .el-form-item__content {
  display: flex;
}
</style>
<style lang="less" scoped>
.enterprise {
  margin: 0 auto;
  width: 68.5vw; /* 边距 */
  padding-top: 30px;
  background: #fff;

  .menuContent {
    background: #fff;
    padding: 0.5vw 2.083vw 1.5625vw;
  }
}

.noRegistration {
  // position: absolute;
  padding: 11.979vw;
  text-align: center;

  // left: 360px;
  div {
    font-size: 0.833vw;
  }

  button {
    font-size: 0.833vw;
    margin-top: 3.6458vw;
    width: 6.25vw;
  }
}

.trainInfo {
  // width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;

  .trainInfo-title {
    font-size: 1.0416vw;
  }

  button {
    margin: 0;
    width: 100px;
    background-color: #2089ff;
    font-size: 14px;
    color: #ffffff;
  }
}

.el-form {
  margin-top: 2.604vw;

  .el-input {
    width: 15.625vw;
    margin-right: 0.52vw;
  }
}

.el-table {
  margin-top: 2.083vw;
}

.tableBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.833vw 0 6.25vw;
}
</style>
